const checkRules = (authority, value) => {
    let onceBothNo = false
    if (authority.includes(1)) {
        onceBothNo = true
        if (value == "") return { handlerFun: false, handlerMes: "内容不能为空" };
    }

    if (authority.includes(2)) {
        onceBothNo = true
        if (isNaN(value)) return { handlerFun: false, handlerMes: "内容只能是数字" };
    }
    if (authority.includes(3)) {
        onceBothNo = true
        if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
            return {
                handlerFun: false,
                handlerMes: "邮箱格式不正确"
            };
        }
    }
    if (authority.includes(4)) {
        onceBothNo = true
        if (!/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(value)) {
            return {
                handlerFun: false,
                handlerMes: "身份证格式不正确"
            };
        }
    }

    if (authority.includes(5)) {
        onceBothNo = true
        if (value.length != 2) return { handlerFun: false, handlerMes: "必须为两个字符" };
    }

    if (authority.includes(6)) {
        onceBothNo = true
        var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
        if (!reg.test(value)) return { handlerFun: false, handlerMes: "必须是字符" };
    }

    if (onceBothNo) {
        return { handlerFun: "", handlerMes: "" }
    }
}

export default checkRules;

// 1 是否必须
// 2 是否必须是数字
// 3 是否匹配邮箱
// 4 是否匹配身份证