<template>
    <div class="apply-promoter">
        <div class="apply-promoter-into-box">
            <van-form style="width: 100%" @submit="submitPromoter" ref="formPromoter">
                <div>
                    <MechanismCheck
                        v-if="active == 0"
                        :MechanismCheckObject="MechanismCheckObject"
                    />
                </div>
                <div></div>
                <div class="submit-box">
                    <van-button type="primary" color="#FB748F" native-type="submit">保存</van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>

<script>
import { Tab, Tabs, Divider, Button, Toast, Form } from "vant";
import MechanismCheck from "@/components/MechanismCheck";
import { addUser } from "@/api/home";
import { certificateList } from "@/api/home";

export default {
    components: {
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [Divider.name]: Divider,
        [Button.name]: Button,
        [Form.name]: Form,
        MechanismCheck,
    },
    data() {
        return {
            result: ["b"],
            active: 0,
            MechanismCheckObject: {
                list: [
                    {
                        text: "学员姓名",
                        placeholder: "请输入学员姓名",
                        value: "",
                        type: 1,
                        serverTitle: "realName",
                        rules: [1],
                    },
                    {
                        text: "身份证号",
                        placeholder: "请输入身份证号",
                        value: "",
                        type: 1,
                        serverTitle: "idCard",
                        rules: [1, 4],
                    },
                    {
                        text: "性别",
                        placeholder: "请选择性别",
                        value: "",
                        showText: "",
                        type: 3,
                        list: [
                            {
                                text: "男",
                                name: "1",
                            },
                            {
                                text: "女",
                                name: "2",
                            },
                        ],
                        isShow: false,
                        serverTitle: "gender",
                    },
                    {
                        text: "手机号",
                        placeholder: "请输入手机号",
                        value: "",
                        type: 1,
                        serverTitle: "mobile",
                        rules: [1],
                    },
                    {
                        text: "学历",
                        placeholder: "请选择学历",
                        value: "",
                        showText: "",
                        type: 3,
                        list: [
                            {
                                text: "初中",
                                name: "初中",
                            },
                            {
                                text: "高中",
                                name: "高中",
                            },
                            {
                                text: "职高",
                                name: "职高",
                            },
                            {
                                text: "中专",
                                name: "中专",
                            },
                            {
                                text: "大专",
                                name: "大专",
                            },
                            {
                                text: "本科",
                                name: "本科",
                            },
                            {
                                text: "研究生",
                                name: "研究生",
                            },
                        ],
                        isShow: false,
                        serverTitle: "education",
                    },
                    {
                        text: "证书",
                        placeholder: "请选择证书",
                        value: [],
                        valueArray: [],
                        showText: "",
                        type: 4,
                        showList: [],
                        isShow: false,
                        serverTitle: "certId",
                    },
                    {
                        text: "学员照片",
                        placeholder: "",
                        type: 2,
                        showList: [],
                        list: [
                            {
                                text: "学员照片",
                                value: "",
                                serverTitle: "img",
                            },
                        ],
                    },
                ],
            },
            certificateList: []
        };
    },
    created() {
        document.title = "招生";
        this.$store.commit("updateBarText", "招生");
        this.init();
    },
    methods: {
        async init() {
            await certificateList().then(res => {
                res.data.data.forEach(v => {
                    v.text = v.name
                    v.name = v.id
                })
                this.MechanismCheckObject.list[5].showList = res.data.data;
            })
        },
        checkListValue(checkArray, callback) {
            for (let i = 0; i < checkArray.length; i++) {
                const element = checkArray[i];
                if (element.hasOwnProperty("list")) {
                    this.checkListValue(element.list, callback);

                } else if (element.value == "") {
                    Toast({ message: element.text + "不能为空", icon: "info-o" });
                    callback(false);
                    break;
                }
            }
        },
        submitPromoter() {
            this.$refs.formPromoter.validate().then(() => {
                let submitObject = {};
                let checkSingle = true;

                this.checkListValue(
                    this.MechanismCheckObject.list,
                    (flagValue) => {
                        checkSingle = flagValue;
                    }
                );

                if (!checkSingle) {
                    return;
                }

                Toast.loading({
                    message: "加载中...",
                    forbidClick: true,
                });


                this.MechanismCheckObject.list.forEach((v) => {
                    switch (v.type) {
                        case 1:
                            if (v.serverTitle == "address") {
                                tempObject.list.forEach((intoV) => {
                                    if (intoV.text == "所在地区") {
                                        v.showText = intoV.value + " " + v.value;
                                    }
                                });
                            }
                            submitObject[v.serverTitle] = v.showText ? v.showText : v.value;
                            break;
                        case 2:
                            v.list.forEach((items) => {
                                submitObject[items.serverTitle] = items.value;
                            });
                            break;
                        case 3:
                            if (v.text == "所在地区") {
                            } else {
                                submitObject[v.serverTitle] = v.value;
                            }
                            break;
                        default:
                            submitObject[v.serverTitle] = v.value;
                            break;
                    }
                });

                addUser(submitObject).then((res) => {
                    const { data } = res
                    if (data.code == 200) {
                        Toast.success({
                            message: data.msg,
                        });
                    }
                });
            });
        },
    },
};
</script>

<style lang="less" scoped>
.apply-promoter {
    width: 100%;
    height: 100%;
    padding-top: 55px;
    .apply-promoter-into-box {
        width: 100%;
        height: 100%;
    }
    .submit-box {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .van-button {
            width: 250px;
            height: 55px;
            color: white;
            box-shadow: 2px 2px 5px #fb748f;
            border-radius: 40px;
        }
    }
}
</style>