<template>
  <div class="mechanism-check">
    <van-cell-group>
      <div v-for="v in MechanismCheckObject.list" :key="v.text">
        <!-- 单行输入 -->
        <van-cell v-if="v.type == 1" :title="v.text" class="field-area">
          <van-field
            v-model="v.value"
            :placeholder="v.placeholder"
            :rules="[
              {
                validator: (value) => checkRules(v.rules, value).handlerFun,
                message: (value) => checkRules(v.rules, value).handlerMes,
              },
            ]"
          />
        </van-cell>
        <!-- 上传 -->
        <van-cell v-if="v.type == 2" :title="v.text" class="upload-area">
          <template #label>
            <div class="upload-box">
              <van-uploader
                v-model="typeV.showList"
                v-for="typeV in v.list"
                :key="typeV.text"
                :after-read="
                  (file) => {
                    afterRead(file, typeV);
                  }
                "
                max-count="1"
              >
                <template #default>
                  <div class="upload-show">
                    <img v-if="fileList.length == 0" src="../../assets/upAdd.png" alt />
                    <img v-else :src="typeV.value" alt />
                  </div>
                </template>
              </van-uploader>
            </div>
          </template>
        </van-cell>
        <!-- 单选 -->
        <van-cell
          v-if="v.type == 3"
          :title="v.text"
          is-link
          @click="v.isShow = !v.isShow"
          class="select-area field-area"
        >
          <template #default>
            <div class="pop-box">{{ v.showText ? v.showText : v.value ? v.value : v.placeholder }}</div>
          </template>
        </van-cell>
        <van-popup v-if="v.type == 3" v-model="v.isShow" position="bottom">
          <van-picker
            :title="v.text"
            show-toolbar
            v-if="v.list"
            :columns="v.list"
            @confirm="
              (value) => {
                v.showText = value.text;
                v.value = value.name;
                v.isShow = false;
              }
            "
            @cancel="() => (v.isShow = false)"
          />
        </van-popup>
        <!-- 多选 -->
        <van-cell
          v-if="v.type == 4"
          :title="v.text"
          is-link
          @click="v.isShow = !v.isShow"
          class="select-area field-area"
        >
          <template #default>
            <div class="pop-box">{{ v.showText ? v.showText : v.placeholder }}</div>
          </template>
        </van-cell>
        <van-popup v-if="v.type == 4" v-model="v.isShow" position="bottom">
          <div class="van-picker__toolbar">
            <button type="button" class="van-picker__cancel" @click="v.isShow = false">取消</button>
            <button type="button" class="van-picker__confirm" @click="confirmMultiple(v)">确认</button>
          </div>
          <div style="max-height:300px;overflow-y:auto">
            <van-checkbox-group v-model="v.valueArray" ref="checkboxGroup">
              <van-cell-group>
                <van-cell
                  v-for="(item, index) in v.showList"
                  clickable
                  :key="item.name"
                  :title="item.text"
                >
                  <template #right-icon>
                    <van-checkbox :name="item" ref="checkboxes" />
                  </template>
                </van-cell>
              </van-cell-group>
            </van-checkbox-group>
          </div>
        </van-popup>
      </div>
    </van-cell-group>
  </div>
</template>

<script>
import {
  CellGroup,
  Field,
  Cell,
  Uploader,
  Icon,
  Area,
  Popup,
  Radio,
  RadioGroup,
  Picker,
  Checkbox,
  CheckboxGroup
} from "vant";
import { areaList } from "@vant/area-data";
import { upLoad } from "@/api/upload";
import checkRules from "@/utils/checkRules";
export default {
  props: ["MechanismCheckObject"],
  components: {
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Cell.name]: Cell,
    [Uploader.name]: Uploader,
    [Icon.name]: Icon,
    [Area.name]: Area,
    [Popup.name]: Popup,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Picker.name]: Picker,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
  },
  data() {
    return {
      areaList,
      insuredInfoDatePopoverBe: false,
      fileList: [],
    };
  },
  methods: {
    confirmMultiple(item) {
      item.value = item.valueArray.map(v => v.id)
      item.showText = item.valueArray.map(v => v.text).join(",")
      item.isShow = false
    },
    checkRules(anthority, value) {
      return checkRules(anthority, value);
    },
    afterRead(file, typeV) {
      var form = new FormData();
      form.append("file", file.file);
      upLoad(form).then((res) => {
        typeV.value = res.data.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.mechanism-check {
  width: 100%;
  .van-cell {
    .van-field {
      padding: 0;
    }
    .upload-box {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .upload-show {
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        .van-icon {
          font-size: 30px;
        }
        span {
          margin-top: 5px;
          font-size: 15px;
          color: #4a4a4a;
          font-weight: 450;
        }
      }
    }
    .check-box-type {
      height: 50px;
      display: flex;
      align-items: center;
      .van-radio-group {
        display: flex;
        .van-radio {
          margin-right: 30px;
          .van-radio__label {
            font-size: 18px;
          }
        }
      }
    }
    .pop-box {
      width: 100%;
      text-align: left;
      color: #c1c2c4;
    }
  }
  .field-area {
    height: 50px;
    border-bottom: 1px solid #f1f1f1;
    .van-cell__title {
      color: #203152;
      font-size: 16px;
      font-family: PingFangSC-Regular, sans-serif;
    }
    .van-cell__value {
      flex: 2;
    }
  }
  .upload-area {
    border-bottom: 1px solid #f1f1f1;
    .van-cell__title {
      color: #203152;
      font-size: 16px;
      font-family: PingFangSC-Regular, sans-serif;
    }
    .van-cell__value {
      flex: 2;
    }
  }
  .select-area {
    border-bottom: 1px solid #f1f1f1;
    .van-cell__value {
      flex: 1.83 !important;
    }
    .van-icon {
      color: #203152;
      font-size: 14px;
    }
  }
}
</style>