var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mechanism-check"},[_c('van-cell-group',_vm._l((_vm.MechanismCheckObject.list),function(v){return _c('div',{key:v.text},[(v.type == 1)?_c('van-cell',{staticClass:"field-area",attrs:{"title":v.text}},[_c('van-field',{attrs:{"placeholder":v.placeholder,"rules":[
            {
              validator: function (value) { return _vm.checkRules(v.rules, value).handlerFun; },
              message: function (value) { return _vm.checkRules(v.rules, value).handlerMes; },
            } ]},model:{value:(v.value),callback:function ($$v) {_vm.$set(v, "value", $$v)},expression:"v.value"}})],1):_vm._e(),(v.type == 2)?_c('van-cell',{staticClass:"upload-area",attrs:{"title":v.text},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"upload-box"},_vm._l((v.list),function(typeV){return _c('van-uploader',{key:typeV.text,attrs:{"after-read":function (file) {
                  _vm.afterRead(file, typeV);
                },"max-count":"1"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"upload-show"},[(_vm.fileList.length == 0)?_c('img',{attrs:{"src":require("../../assets/upAdd.png"),"alt":""}}):_c('img',{attrs:{"src":typeV.value,"alt":""}})])]},proxy:true}],null,true),model:{value:(typeV.showList),callback:function ($$v) {_vm.$set(typeV, "showList", $$v)},expression:"typeV.showList"}})}),1)]},proxy:true}],null,true)}):_vm._e(),(v.type == 3)?_c('van-cell',{staticClass:"select-area field-area",attrs:{"title":v.text,"is-link":""},on:{"click":function($event){v.isShow = !v.isShow}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"pop-box"},[_vm._v(_vm._s(v.showText ? v.showText : v.value ? v.value : v.placeholder))])]},proxy:true}],null,true)}):_vm._e(),(v.type == 3)?_c('van-popup',{attrs:{"position":"bottom"},model:{value:(v.isShow),callback:function ($$v) {_vm.$set(v, "isShow", $$v)},expression:"v.isShow"}},[(v.list)?_c('van-picker',{attrs:{"title":v.text,"show-toolbar":"","columns":v.list},on:{"confirm":function (value) {
              v.showText = value.text;
              v.value = value.name;
              v.isShow = false;
            },"cancel":function () { return (v.isShow = false); }}}):_vm._e()],1):_vm._e(),(v.type == 4)?_c('van-cell',{staticClass:"select-area field-area",attrs:{"title":v.text,"is-link":""},on:{"click":function($event){v.isShow = !v.isShow}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"pop-box"},[_vm._v(_vm._s(v.showText ? v.showText : v.placeholder))])]},proxy:true}],null,true)}):_vm._e(),(v.type == 4)?_c('van-popup',{attrs:{"position":"bottom"},model:{value:(v.isShow),callback:function ($$v) {_vm.$set(v, "isShow", $$v)},expression:"v.isShow"}},[_c('div',{staticClass:"van-picker__toolbar"},[_c('button',{staticClass:"van-picker__cancel",attrs:{"type":"button"},on:{"click":function($event){v.isShow = false}}},[_vm._v("取消")]),_c('button',{staticClass:"van-picker__confirm",attrs:{"type":"button"},on:{"click":function($event){return _vm.confirmMultiple(v)}}},[_vm._v("确认")])]),_c('div',{staticStyle:{"max-height":"300px","overflow-y":"auto"}},[_c('van-checkbox-group',{ref:"checkboxGroup",refInFor:true,model:{value:(v.valueArray),callback:function ($$v) {_vm.$set(v, "valueArray", $$v)},expression:"v.valueArray"}},[_c('van-cell-group',_vm._l((v.showList),function(item,index){return _c('van-cell',{key:item.name,attrs:{"clickable":"","title":item.text},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('van-checkbox',{ref:"checkboxes",refInFor:true,attrs:{"name":item}})]},proxy:true}],null,true)})}),1)],1)],1)]):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }